import { clsx } from 'clsx'
import { useProgressData } from '../providers/progress-data-provider'
import { useCallback, useState } from 'react'
import { ProductDto } from '@codecorp/web-api-wrapper'

export interface DeviceSelectorCardProps {
    device: ProductDto
    discontinued?: boolean
}

export function DeviceSelectorCard({
    device,
    discontinued = false,
}: DeviceSelectorCardProps) {
    const { dispatch } = useProgressData()
    const [imagesLoaded, setImagesLoaded] = useState(false)

    const handleClick = useCallback(() => {
        if (discontinued) return

        dispatch({ type: 'deviceSelected', device })
    }, [dispatch, discontinued, device])

    return (
        <div className="w-full">
            <button
                className="group relative flex flex-col items-center justify-center"
                onClick={handleClick}
            >
                {discontinued && (
                    <span className="absolute top-0 left-0 z-10 w-fit bg-black px-1 text-left text-xl font-bold italic text-white md:text-sm">
                        DISCONTINUED
                    </span>
                )}
                <img
                    className="ease h-full w-[600px] scale-100 transition duration-300 group-hover:scale-110"
                    src={device.productImage}
                    alt={device.title}
                    onLoad={() => setImagesLoaded(true)}
                />
                <span
                    className={clsx(
                        'absolute bottom-0 w-full bg-zinc-800 bg-opacity-40 p-1 text-left font-bold group-hover:bg-opacity-90',
                        imagesLoaded ? 'opacity-100' : 'opacity-0',
                    )}
                >
                    <p className="ml-2 text-2xl text-white md:text-base">
                        {device.title}
                    </p>
                </span>
            </button>
        </div>
    )
}
