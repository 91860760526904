import * as base from '@radix-ui/react-accordion'
import { ChevronDownIcon } from '@radix-ui/react-icons'
import { ClassValue, clsx } from 'clsx'
import {
    ComponentPropsWithRef,
    ComponentRef,
    forwardRef,
    ReactNode,
    useRef,
} from 'react'
import { Props } from '../types'
import '@codecorp/util/polyfills'
import scrollIntoView from 'scroll-into-view-if-needed'
import { nextAnimationFrame, sleep } from '@codecorp/util/promise'
import { Panel } from './panel'

export type AccordionChildren = Record<string, ReactNode>

export type AccordionProps = Props<
    {
        items: AccordionChildren
        headerElement?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
        contentClassName?: ClassValue
        scrollIntoView?: boolean
        fullWidth?: boolean
    } & ComponentPropsWithRef<typeof base.Root>
    // this doesn't work now if the type isn't merged here, for some reason
    // excluding anything, even a key that isn't present, causes the type to
    // only accept the `type="multiple" variant of the props. hack around to get
    // the other props that `Props` provides. not sure what broke it, probably
    // a radix update?
>

export const Accordion = forwardRef<
    ComponentRef<typeof base.Root>,
    AccordionProps
>(
    (
        {
            className,
            contentClassName,
            items,
            headerElement: Header = 'h3',
            scrollIntoView: shouldScrollIntoView = true,
            fullWidth = false,
            onValueChange,
            ...props
        },
        ref,
    ) => {
        const itemRefs = useRef<Record<string, HTMLDivElement>>({})
        const prevOpenItems = useRef<Set<string>>(new Set())

        return (
            <base.Root
                ref={ref}
                className={clsx(
                    className,
                    'space-y-6',
                    fullWidth ? 'max-w-full' : 'max-w-prose',
                )}
                onValueChange={async (items: string & string[]) => {
                    onValueChange?.(items)
                    if (shouldScrollIntoView) {
                        const openItems = new Set(
                            Array.isArray(items) ? items : [items],
                        )
                        try {
                            const [newItem] = openItems.difference(
                                prevOpenItems.current,
                            )

                            if (newItem == null) return
                            const el = itemRefs.current[newItem]
                            if (el == null) return

                            // https://stackoverflow.com/questions/26556436/react-after-render-code
                            // can't use useState and useEffect since that state is possibly handled by radix
                            // and redoing that logic here and allowing to still be optionally uncontrolled is complicated

                            // once to fully display the header, then again after the content is expanded
                            await nextAnimationFrame()
                            scrollIntoView(el, {
                                behavior: 'smooth',
                                scrollMode: 'if-needed',
                                block: 'nearest',
                            })

                            await sleep(300) // animation is 300ms, don't think there's a dynamic way to get this
                            await nextAnimationFrame()
                            scrollIntoView(el, {
                                behavior: 'smooth',
                                scrollMode: 'if-needed',
                                block: 'nearest',
                            })
                        } finally {
                            prevOpenItems.current = openItems
                        }
                    }
                }}
                {...props}
            >
                {Object.entries(items).map(([q, a]) => (
                    <base.Item
                        key={q}
                        value={q}
                        ref={(el) => {
                            if (el != null) itemRefs.current[q] = el
                            else delete itemRefs.current[q]
                        }}
                        asChild
                    >
                        <Panel
                            smallCorners
                            dense
                        >
                            <base.Header
                                asChild
                                className="flex"
                            >
                                <Header>
                                    <base.Trigger className="flex flex-1 select-none justify-between p-4 text-left text-lg font-medium text-gray-800 dark:text-gray-300">
                                        <div
                                            // I have no idea why but I get weird overflow without an explicit absolute width
                                            className="w-0 flex-1 truncate"
                                            title={q}
                                        >
                                            {q}
                                        </div>
                                        <ChevronDownIcon
                                            aria-hidden
                                            className="self-center"
                                        />
                                    </base.Trigger>
                                </Header>
                            </base.Header>
                            <base.Content className="radix-state-open:animate-radix-accordion-expand radix-state-closed:animate-radix-accordion-collapse overflow-hidden border-t-2 dark:border-gray-800">
                                <div className={clsx('p-4', contentClassName)}>
                                    {a}
                                </div>
                            </base.Content>
                        </Panel>
                    </base.Item>
                ))}
            </base.Root>
        )
    },
)
