import { Button } from '@codecorp/components'
import { CategoryChecklistItem } from './category-checklist-item'
import { DownloadIcon } from '@radix-ui/react-icons'
import { useProgressData } from '../providers/progress-data-provider'
import { useCallback, useState } from 'react'
import { Setting } from './settings-list-content'
import {
    ProductDto,
    useMcodesGetDeviceCategoriesQuery,
} from '@codecorp/web-api-wrapper'
import clsx from 'clsx'
import { useBranding } from '../providers/branding-context'

export interface SettingsCategory {
    title: string
    settings: Setting[]
}

export interface CategoryChecklistProps {
    selectedDevice: ProductDto
}

export const CategoryChecklist = ({
    selectedDevice,
}: CategoryChecklistProps) => {
    const [selectedCategories, setSelectedCategories] = useState<string[]>([])
    const { dispatch } = useProgressData()
    const { data: allCategories = [] } = useMcodesGetDeviceCategoriesQuery({
        deviceName: selectedDevice.name,
    })
    const brand = useBranding()

    const fullCatalogUrl = `${import.meta.env.VITE_API_URL}/api/mcodes/report/${
        selectedDevice.title
    }/full?branding=${brand}`

    const handleSelectChange = useCallback(
        (category: string, isSelected: boolean) => {
            setSelectedCategories((categories) => {
                if (isSelected) {
                    return [...categories, category]
                } else {
                    const i = categories.indexOf(category)
                    return [
                        ...categories.slice(0, i),
                        ...categories.slice(i + 1),
                    ]
                }
            })
        },
        [],
    )

    const handleNext = useCallback(() => {
        dispatch({ type: 'categoriesSelected', categories: selectedCategories })
    }, [dispatch, selectedCategories])

    const handleBack = useCallback(() => {
        dispatch({ type: 'backButtonPressed' })
    }, [dispatch])

    return (
        <section
            className={clsx(
                'mt-8 transition-opacity duration-500',
                allCategories.length > 0 ? 'opactiy-100' : 'opacity-0',
            )}
        >
            <h2 className="p-2 text-xl font-bold text-gray-700">
                What type of category are you looking for?
            </h2>
            {allCategories && (
                <ul className="rounded-lg border-2 border-gray-300 px-4 shadow-sm">
                    {allCategories.map((category, index) => {
                        return (
                            <li key={category}>
                                <CategoryChecklistItem
                                    category={category}
                                    selectedCategories={selectedCategories}
                                    onSelectChange={handleSelectChange}
                                />
                                {index !== allCategories.length - 1 && (
                                    <hr className="mt-2 w-full border-gray-300" />
                                )}
                            </li>
                        )
                    })}
                </ul>
            )}
            <div className="my-4 flex h-12 w-full items-center justify-between">
                <Button
                    className="w-20"
                    filled
                    secondary
                    onPress={handleBack}
                >
                    Back
                </Button>
                <h2 className="text-center text-lg font-bold">
                    {selectedDevice.title}
                </h2>
                <Button
                    className="w-20"
                    filled
                    onPress={handleNext}
                    isDisabled={selectedCategories.length === 0}
                >
                    Next
                </Button>
            </div>
            <hr />
            <div className="mt-4 flex flex-col items-center text-center text-sm">
                <p>
                    If you would like to download all the codes for{' '}
                    {selectedDevice.title} please do so here.
                </p>
                <Button
                    className="w-18 mt-2 flex text-xs"
                    as="a"
                    href={fullCatalogUrl}
                    filled
                    download
                    target="_blank"
                >
                    <DownloadIcon className="mr-2" />
                    DOWNLOAD FULL DEVICE CATALOG
                </Button>
            </div>
        </section>
    )
}
