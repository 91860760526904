import { curry, includes, pipe, pluck, values } from 'ramda'
import { match, P } from 'ts-pattern'
import * as strFormat from '@codecorp/util/str-format'

export const optionDevices = [
    'CR1100',
    'CR2700',
    'CR1500',
    'CR5200',
    // ranger, no options yet
    // 'V1300', 'V4500',
    '1500DPM',
    'CR8200',
    'CR950',
] as const

// original advanced config had more options, not added to rest of app yet
export type OptionDevice = (typeof optionDevices)[number]

export const isOptionDevice = (device: unknown): device is OptionDevice =>
    typeof device === 'string' && optionDevices.includes(device)

export interface OptionBase {
    type: string
    label?: string
    applicableDevices: OptionDevice[]
}

export interface BooleanOption extends OptionBase {
    type: 'boolean'
    label: string
    /// if the option is enabled, this cmd is included. empty string means no cmd.
    enabledCmd: string
    /// if the option is disabled, this cmd is included. empty string means no cmd. undefined means it is not selectable
    disabledCmd?: string
}

export interface OneOfOption extends OptionBase {
    type: 'oneOf'
    variant: 'select' | 'radio'
    options: { name: string; cmd: string }[]
}

export interface FormattedOption<T extends string> extends OptionBase {
    formatter: T
}

export interface StringOption
    extends FormattedOption<`${string}{value}${string}`> {
    type: 'string'
}

export interface NumberOption
    extends FormattedOption<`${string}{value}${string}`> {
    type: 'number'
    bounds: [lower: number | undefined, upper: number | undefined]
}

type SingularOption = BooleanOption | OneOfOption | StringOption | NumberOption

/// a multi option is a wrapper around another option that lets the user make another selection if the previous one is not unset
export interface MultiOption extends OptionBase {
    type: 'multi'
    option: SingularOption
}

export type Option = SingularOption | MultiOption
export type LabeledOption = Option & { label: NonNullable<Option['label']> }

/**
 * Determines whether a cmd is claimed by an option.
 * @param option The option to check.
 * @param cmd The command to check against.
 * @returns A boolean indicating whether the option claims the cmd.
 */
export const isClaimingCmd = curry((option: Option, cmd: string): boolean =>
    match(option)
        .with(
            {
                type: 'boolean',
                enabledCmd: P.optional(P.select('a')),
                disabledCmd: P.optional(P.select('b')),
            },
            (result) => {
                return pipe(values, includes(cmd))(result) as boolean
            },
        )
        .with({ type: 'oneOf', options: P.select() }, (result) => {
            // Break it into separate operations to help TypeScript infer correctly
            const pluckedCmds = pluck('cmd')(result as { cmd: string }[])
            if (!Array.isArray(pluckedCmds)) {
                throw new Error('Unexpected type, expected an array')
            }
            return pluckedCmds.includes(cmd)
        })
        .with({ formatter: P.select() }, (f) => strFormat.match(f, cmd))
        .with({ type: 'multi', option: P.select() }, (o) =>
            isClaimingCmd(o, cmd),
        )
        .exhaustive(),
)

export const getStateOfBooleanOption = curry(
    ({ enabledCmd, disabledCmd }: BooleanOption, cmds: string[]) =>
        cmds.includes(enabledCmd) ||
        (disabledCmd == null
            ? false
            : cmds.includes(disabledCmd)
              ? false
              : undefined),
)

export const getStateOfOneOfOption = (
    { options }: OneOfOption,
    cmds: string[],
) => options.find(({ cmd }) => cmds.includes(cmd))

export const getStateOfStringOption = <T extends string>(
    { formatter }: FormattedOption<T>,
    cmds: string[],
): Record<strFormat.FormatArgs<T>, string> | null =>
    cmds
        .map((c) => strFormat.getPlaceholderValues(formatter, c))
        .filter(Boolean)
        .reduce(
            (a, b) => ({ ...a, ...b }),
            {} as Record<strFormat.FormatArgs<T>, string>,
        )

export const getStateOfNumberOption = (
    option: NumberOption,
    cmds: string[],
): number | undefined => {
    const res = Number.parseFloat(
        getStateOfStringOption(option, cmds)?.value ?? '',
    )
    return Number.isFinite(res) ? res : undefined
}

export const modifyCmds = curry(
    (
        {
            include = [],
            exclude = [],
        }: { include?: string[]; exclude?: string[] },
        cmds: string[],
    ) =>
        cmds
            .filter((cmd) => !exclude.includes(cmd))
            .concat(include.filter((cmd) => !cmds.includes(cmd))),
)
