import { emptySplitApi as api } from './empty-api'
export const addTagTypes = [
    'GoCode',
    'Configs',
    'MDL',
    'Readers',
    'ServerInfo',
    'MyCode',
    'Admin',
    'CortexScanPlus',
    'Graphs',
    'Registration',
    'Organization',
    'User',
    'McodeAdmin',
    'Mcodes',
    'Report',
    'Licensing',
    'CodeDM',
    'Cleanliness Results',
] as const
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            goCodeIndex: build.mutation<
                GoCodeIndexApiResponse,
                GoCodeIndexApiArg
            >({
                query: (queryArg) => ({
                    url: `/gocode`,
                    method: 'POST',
                    body: queryArg.body,
                }),
                invalidatesTags: ['GoCode'],
            }),
            configsGetAllConfigs: build.query<
                ConfigsGetAllConfigsApiResponse,
                ConfigsGetAllConfigsApiArg
            >({
                query: () => ({ url: `/api/configs` }),
                providesTags: ['Configs'],
            }),
            configsGetAllConfigsByCid: build.query<
                ConfigsGetAllConfigsByCidApiResponse,
                ConfigsGetAllConfigsByCidApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/configs/cid/${queryArg.cid}`,
                }),
                providesTags: ['Configs'],
            }),
            configsGetConfigById: build.query<
                ConfigsGetConfigByIdApiResponse,
                ConfigsGetConfigByIdApiArg
            >({
                query: (queryArg) => ({ url: `/api/configs/${queryArg.id}` }),
                providesTags: ['Configs'],
            }),
            configsCreate: build.mutation<
                ConfigsCreateApiResponse,
                ConfigsCreateApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/configs/Create`,
                    method: 'POST',
                    params: { collection: queryArg.collection },
                }),
                invalidatesTags: ['Configs'],
            }),
            configsEdit: build.mutation<
                ConfigsEditApiResponse,
                ConfigsEditApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/configs/Edit`,
                    method: 'POST',
                    params: {
                        id: queryArg.id,
                        collection: queryArg.collection,
                    },
                }),
                invalidatesTags: ['Configs'],
            }),
            configsDelete: build.mutation<
                ConfigsDeleteApiResponse,
                ConfigsDeleteApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/configs/Delete`,
                    method: 'POST',
                    params: {
                        id: queryArg.id,
                        collection: queryArg.collection,
                    },
                }),
                invalidatesTags: ['Configs'],
            }),
            mdlSaveMDlDataInDb: build.mutation<
                MdlSaveMDlDataInDbApiResponse,
                MdlSaveMDlDataInDbApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mdl`,
                    method: 'POST',
                    body: queryArg.body,
                }),
                invalidatesTags: ['MDL'],
            }),
            readersGetReadersList: build.query<
                ReadersGetReadersListApiResponse,
                ReadersGetReadersListApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/readers/${queryArg.customerid}/${queryArg.connectstatus}`,
                }),
                providesTags: ['Readers'],
            }),
            readersGetReaderById: build.query<
                ReadersGetReaderByIdApiResponse,
                ReadersGetReaderByIdApiArg
            >({
                query: (queryArg) => ({ url: `/api/readers/${queryArg.id}` }),
                providesTags: ['Readers'],
            }),
            readersEditReader: build.mutation<
                ReadersEditReaderApiResponse,
                ReadersEditReaderApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/readers/${queryArg.id}`,
                    method: 'PUT',
                    body: queryArg.readerDto,
                }),
                invalidatesTags: ['Readers'],
            }),
            readersDeleteReader: build.mutation<
                ReadersDeleteReaderApiResponse,
                ReadersDeleteReaderApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/readers/${queryArg.id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Readers'],
            }),
            readersCreateReader: build.mutation<
                ReadersCreateReaderApiResponse,
                ReadersCreateReaderApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/readers/${queryArg.customerid}`,
                    method: 'POST',
                    body: queryArg.readerDto,
                }),
                invalidatesTags: ['Readers'],
            }),
            serverInfoGetServerInfo: build.query<
                ServerInfoGetServerInfoApiResponse,
                ServerInfoGetServerInfoApiArg
            >({
                query: () => ({ url: `/server-info` }),
                providesTags: ['ServerInfo'],
            }),
            purchaseOrderReferenceCreatePurchaseOrder: build.mutation<
                PurchaseOrderReferenceCreatePurchaseOrderApiResponse,
                PurchaseOrderReferenceCreatePurchaseOrderApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/purchaseorderreference/create`,
                    method: 'POST',
                    body: queryArg.purchaseOrderRequestDto,
                }),
                invalidatesTags: ['MyCode'],
            }),
            purchaseOrderReferenceGetPurchaseOrder: build.query<
                PurchaseOrderReferenceGetPurchaseOrderApiResponse,
                PurchaseOrderReferenceGetPurchaseOrderApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/purchaseorderreference/${queryArg.id}`,
                }),
                providesTags: ['MyCode'],
            }),
            licensingUserRequestAccessesCreateUserRequest: build.mutation<
                LicensingUserRequestAccessesCreateUserRequestApiResponse,
                LicensingUserRequestAccessesCreateUserRequestApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/user-request-access/create`,
                    method: 'POST',
                    body: queryArg.licensingUserRequestAccessDto,
                }),
                invalidatesTags: ['MyCode'],
            }),
            licensingUserRequestAccessesGetUserRequest: build.query<
                LicensingUserRequestAccessesGetUserRequestApiResponse,
                LicensingUserRequestAccessesGetUserRequestApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/user-request-access/${queryArg.email}`,
                }),
                providesTags: ['MyCode'],
            }),
            licensingUserRequestAccessesDeleteUserRequest: build.mutation<
                LicensingUserRequestAccessesDeleteUserRequestApiResponse,
                LicensingUserRequestAccessesDeleteUserRequestApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/user-request-access/${queryArg.email}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['MyCode'],
            }),
            adminGetOrganizations: build.query<
                AdminGetOrganizationsApiResponse,
                AdminGetOrganizationsApiArg
            >({
                query: () => ({ url: `/api/MyCode/admin/organizations` }),
                providesTags: ['Admin'],
            }),
            cortexScanUserGetCortexScanUser: build.query<
                CortexScanUserGetCortexScanUserApiResponse,
                CortexScanUserGetCortexScanUserApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/cortexscanusers`,
                    params: { email: queryArg.email },
                }),
                providesTags: ['CortexScanPlus'],
            }),
            cortexScanUserUpdateCortexScanUser: build.mutation<
                CortexScanUserUpdateCortexScanUserApiResponse,
                CortexScanUserUpdateCortexScanUserApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/cortexscanusers`,
                    method: 'POST',
                    body: queryArg.cortexscanUserPostDto,
                }),
                invalidatesTags: ['CortexScanPlus'],
            }),
            cortexScanUserDeleteCortexScanUser: build.mutation<
                CortexScanUserDeleteCortexScanUserApiResponse,
                CortexScanUserDeleteCortexScanUserApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/cortexscanusers`,
                    method: 'DELETE',
                    params: { email: queryArg.email },
                }),
                invalidatesTags: ['CortexScanPlus'],
            }),
            countryGetCountries: build.query<
                CountryGetCountriesApiResponse,
                CountryGetCountriesApiArg
            >({
                query: () => ({ url: `/api/mycode/country` }),
                providesTags: ['MyCode'],
            }),
            graphOrganizationUniqueDevicesByTime: build.query<
                GraphOrganizationUniqueDevicesByTimeApiResponse,
                GraphOrganizationUniqueDevicesByTimeApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/data/graphs/organization/${queryArg.organizationId}/unique-devices-by-time`,
                }),
                providesTags: ['Graphs'],
            }),
            graphGlobalUniqueDevicesByTime: build.query<
                GraphGlobalUniqueDevicesByTimeApiResponse,
                GraphGlobalUniqueDevicesByTimeApiArg
            >({
                query: () => ({
                    url: `/api/mycode/data/graphs/global/unique-devices-by-time`,
                }),
                providesTags: ['Graphs'],
            }),
            graphOrganizationMostFrequentDeviceModels: build.query<
                GraphOrganizationMostFrequentDeviceModelsApiResponse,
                GraphOrganizationMostFrequentDeviceModelsApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/data/graphs/organization/${queryArg.organizationId}/most-frequent-device-models`,
                }),
                providesTags: ['Graphs'],
            }),
            graphGlobalMostFrequentDeviceModels: build.query<
                GraphGlobalMostFrequentDeviceModelsApiResponse,
                GraphGlobalMostFrequentDeviceModelsApiArg
            >({
                query: () => ({
                    url: `/api/mycode/data/graphs/global/most-frequent-device-models`,
                }),
                providesTags: ['Graphs'],
            }),
            graphOrganizationTotalDevicesByOs: build.query<
                GraphOrganizationTotalDevicesByOsApiResponse,
                GraphOrganizationTotalDevicesByOsApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/data/graphs/organization/${queryArg.organizationId}/total-devices-by-os`,
                }),
                providesTags: ['Graphs'],
            }),
            graphGlobalTotalDevicesByOs: build.query<
                GraphGlobalTotalDevicesByOsApiResponse,
                GraphGlobalTotalDevicesByOsApiArg
            >({
                query: () => ({
                    url: `/api/mycode/data/graphs/global/total-devices-by-os`,
                }),
                providesTags: ['Graphs'],
            }),
            graphOrganizationUniqueDevicesByLocation: build.query<
                GraphOrganizationUniqueDevicesByLocationApiResponse,
                GraphOrganizationUniqueDevicesByLocationApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/data/graphs/organization/${queryArg.organizationId}/unique-devices-by-location`,
                }),
                providesTags: ['Graphs'],
            }),
            graphGlobalUniqueDevicesByLocation: build.query<
                GraphGlobalUniqueDevicesByLocationApiResponse,
                GraphGlobalUniqueDevicesByLocationApiArg
            >({
                query: () => ({
                    url: `/api/mycode/data/graphs/global/unique-devices-by-location`,
                }),
                providesTags: ['Graphs'],
            }),
            graphOrganizationLicenseCounts: build.query<
                GraphOrganizationLicenseCountsApiResponse,
                GraphOrganizationLicenseCountsApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/data/graphs/organization/${queryArg.organizationId}/license-counts`,
                }),
                providesTags: ['Graphs'],
            }),
            graphGlobalLicenseCounts: build.query<
                GraphGlobalLicenseCountsApiResponse,
                GraphGlobalLicenseCountsApiArg
            >({
                query: () => ({
                    url: `/api/mycode/data/graphs/global/license-counts`,
                }),
                providesTags: ['Graphs'],
            }),
            groupGetGroups: build.query<
                GroupGetGroupsApiResponse,
                GroupGetGroupsApiArg
            >({
                query: () => ({ url: `/api/mycode/group` }),
                providesTags: ['MyCode'],
            }),
            groupCreateGroup: build.mutation<
                GroupCreateGroupApiResponse,
                GroupCreateGroupApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/group`,
                    method: 'POST',
                    body: queryArg.createGroupRequest,
                }),
                invalidatesTags: ['MyCode'],
            }),
            groupGetGroupById: build.query<
                GroupGetGroupByIdApiResponse,
                GroupGetGroupByIdApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/group/${queryArg.id}`,
                }),
                providesTags: ['MyCode'],
            }),
            groupDeleteGroup: build.mutation<
                GroupDeleteGroupApiResponse,
                GroupDeleteGroupApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/group/${queryArg.id}`,
                    method: 'DELETE',
                    body: queryArg.deleteGroupRequest,
                }),
                invalidatesTags: ['MyCode'],
            }),
            groupRenameGroup: build.mutation<
                GroupRenameGroupApiResponse,
                GroupRenameGroupApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/group/${queryArg.id}/rename`,
                    method: 'PUT',
                    body: queryArg.renameGroupRequest,
                }),
                invalidatesTags: ['MyCode'],
            }),
            groupSetGroupProducts: build.mutation<
                GroupSetGroupProductsApiResponse,
                GroupSetGroupProductsApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/group/${queryArg.id}/products`,
                    method: 'PUT',
                    body: queryArg.productListRequest,
                }),
                invalidatesTags: ['Registration'],
            }),
            groupJoinGroup: build.mutation<
                GroupJoinGroupApiResponse,
                GroupJoinGroupApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/group/${queryArg.id}/join`,
                    method: 'POST',
                }),
                invalidatesTags: ['MyCode'],
            }),
            marketingAll: build.query<
                MarketingAllApiResponse,
                MarketingAllApiArg
            >({
                query: () => ({ url: `/api/mycode/marketing` }),
                providesTags: ['MyCode'],
            }),
            marketingCreateAd: build.mutation<
                MarketingCreateAdApiResponse,
                MarketingCreateAdApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/marketing`,
                    method: 'POST',
                    body: queryArg.ad,
                }),
                invalidatesTags: ['MyCode'],
            }),
            marketingIndex: build.query<
                MarketingIndexApiResponse,
                MarketingIndexApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/marketing/${queryArg.id}`,
                }),
                providesTags: ['MyCode'],
            }),
            marketingUpdateAd: build.mutation<
                MarketingUpdateAdApiResponse,
                MarketingUpdateAdApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/marketing/${queryArg.id}`,
                    method: 'PUT',
                    body: queryArg.ad,
                }),
                invalidatesTags: ['MyCode'],
            }),
            marketingDestroy: build.mutation<
                MarketingDestroyApiResponse,
                MarketingDestroyApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/marketing/${queryArg.id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['MyCode'],
            }),
            organizationGetOrganization: build.query<
                OrganizationGetOrganizationApiResponse,
                OrganizationGetOrganizationApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/MyCode/organization/${queryArg.organizationId}`,
                }),
                providesTags: ['Organization'],
            }),
            organizationGetUsers: build.query<
                OrganizationGetUsersApiResponse,
                OrganizationGetUsersApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/MyCode/organization/${queryArg.organizationId}/users`,
                }),
                providesTags: ['Organization'],
            }),
            productGetProducts: build.query<
                ProductGetProductsApiResponse,
                ProductGetProductsApiArg
            >({
                query: () => ({ url: `/api/mycode/product` }),
                providesTags: ['MyCode'],
            }),
            productGetProductById: build.query<
                ProductGetProductByIdApiResponse,
                ProductGetProductByIdApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/product/${queryArg.id}`,
                }),
                providesTags: ['MyCode'],
            }),
            registrationRegisterUser: build.mutation<
                RegistrationRegisterUserApiResponse,
                RegistrationRegisterUserApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/register`,
                    method: 'POST',
                    body: queryArg.userRegistrationRequest,
                }),
                invalidatesTags: ['Registration', 'User'],
            }),
            registrationGetRemainingRegistrationTasks: build.query<
                RegistrationGetRemainingRegistrationTasksApiResponse,
                RegistrationGetRemainingRegistrationTasksApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/register`,
                    params: { registrationApp: queryArg.registrationApp },
                }),
                providesTags: ['Registration', 'User'],
            }),
            registrationRegisterOrganization: build.mutation<
                RegistrationRegisterOrganizationApiResponse,
                RegistrationRegisterOrganizationApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/register/organization`,
                    method: 'POST',
                    params: { companyName: queryArg.companyName },
                }),
                invalidatesTags: ['Registration', 'User'],
            }),
            salesforceGetCasesByEmail: build.query<
                SalesforceGetCasesByEmailApiResponse,
                SalesforceGetCasesByEmailApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/salesforce/email/${queryArg.email}`,
                }),
                providesTags: ['MyCode'],
            }),
            salesforceGetCasesForAdmin: build.query<
                SalesforceGetCasesForAdminApiResponse,
                SalesforceGetCasesForAdminApiArg
            >({
                query: () => ({ url: `/api/mycode/salesforce/admin` }),
                providesTags: ['MyCode'],
            }),
            salesforceGetCasesById: build.query<
                SalesforceGetCasesByIdApiResponse,
                SalesforceGetCasesByIdApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/salesforce/id/${queryArg.id}`,
                }),
                providesTags: ['MyCode'],
            }),
            salesforceVerifyEmail: build.query<
                SalesforceVerifyEmailApiResponse,
                SalesforceVerifyEmailApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/salesforce/verify/${queryArg.email}`,
                }),
                providesTags: ['MyCode'],
            }),
            salesforceCreateCase: build.mutation<
                SalesforceCreateCaseApiResponse,
                SalesforceCreateCaseApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/salesforce`,
                    method: 'POST',
                    body: queryArg.createCaseModel,
                }),
                invalidatesTags: ['MyCode'],
            }),
            salesforceUpdateCase: build.mutation<
                SalesforceUpdateCaseApiResponse,
                SalesforceUpdateCaseApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/salesforce/update`,
                    method: 'PATCH',
                    body: queryArg.body,
                }),
                invalidatesTags: ['MyCode'],
            }),
            salesforceDeleteCasesById: build.mutation<
                SalesforceDeleteCasesByIdApiResponse,
                SalesforceDeleteCasesByIdApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/salesforce/${queryArg.id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['MyCode'],
            }),
            userGetAllPendingUsers: build.query<
                UserGetAllPendingUsersApiResponse,
                UserGetAllPendingUsersApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/users/pending`,
                    params: {
                        PageNumber: queryArg.pageNumber,
                        PageSize: queryArg.pageSize,
                        textFilter: queryArg.textFilter,
                    },
                }),
                providesTags: ['MyCode'],
            }),
            userGetAllUsers: build.query<
                UserGetAllUsersApiResponse,
                UserGetAllUsersApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/users`,
                    params: {
                        PageNumber: queryArg.pageNumber,
                        PageSize: queryArg.pageSize,
                        textFilter: queryArg.textFilter,
                    },
                }),
                providesTags: ['MyCode'],
            }),
            userGetFullListOfUsers: build.query<
                UserGetFullListOfUsersApiResponse,
                UserGetFullListOfUsersApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/users/all-users`,
                    params: {
                        PageNumber: queryArg.pageNumber,
                        PageSize: queryArg.pageSize,
                        textFilter: queryArg.textFilter,
                    },
                }),
                providesTags: ['MyCode'],
            }),
            userDownloadFullListOfUsersExcel: build.query<
                UserDownloadFullListOfUsersExcelApiResponse,
                UserDownloadFullListOfUsersExcelApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/users/download-all-users-excel`,
                    params: { textFilter: queryArg.textFilter },
                }),
                providesTags: ['MyCode'],
            }),
            userGetCurrentUser: build.query<
                UserGetCurrentUserApiResponse,
                UserGetCurrentUserApiArg
            >({
                query: () => ({ url: `/api/mycode/users/current` }),
                providesTags: ['MyCode'],
            }),
            userUpdateCurrentUser: build.mutation<
                UserUpdateCurrentUserApiResponse,
                UserUpdateCurrentUserApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/users/current`,
                    method: 'PUT',
                    body: queryArg.updateUserDto,
                }),
                invalidatesTags: ['MyCode'],
            }),
            userUpdateUser: build.mutation<
                UserUpdateUserApiResponse,
                UserUpdateUserApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/users/${queryArg.userId}`,
                    method: 'PUT',
                    body: queryArg.updateUserDto,
                }),
                invalidatesTags: ['MyCode'],
            }),
            userDeleteUser: build.mutation<
                UserDeleteUserApiResponse,
                UserDeleteUserApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/users/${queryArg.userId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['MyCode'],
            }),
            userAddUser: build.mutation<
                UserAddUserApiResponse,
                UserAddUserApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mycode/users/add-new-user`,
                    method: 'POST',
                    body: queryArg.userRegistrationRequest,
                    params: { companyName: queryArg.companyName },
                }),
                invalidatesTags: ['MyCode'],
            }),
            mcodeAdminCreateOrEditProduct: build.mutation<
                McodeAdminCreateOrEditProductApiResponse,
                McodeAdminCreateOrEditProductApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mcodes/admin/product`,
                    method: 'POST',
                    body: queryArg.productDto,
                }),
                invalidatesTags: ['McodeAdmin'],
            }),
            mcodesGetConfigurableDevices: build.query<
                McodesGetConfigurableDevicesApiResponse,
                McodesGetConfigurableDevicesApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mcodes/configurable-devices`,
                    params: { brand: queryArg.brand },
                }),
                providesTags: ['Mcodes'],
            }),
            mcodesGetDeviceCategories: build.query<
                McodesGetDeviceCategoriesApiResponse,
                McodesGetDeviceCategoriesApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mcodes/categories/${queryArg.deviceName}`,
                }),
                providesTags: ['Mcodes'],
            }),
            mcodesGetCategorySettings: build.query<
                McodesGetCategorySettingsApiResponse,
                McodesGetCategorySettingsApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mcodes/settings/${queryArg.deviceName}`,
                    params: { category: queryArg.category },
                }),
                providesTags: ['Mcodes'],
            }),
            reportGetFullMcodeReport: build.query<
                ReportGetFullMcodeReportApiResponse,
                ReportGetFullMcodeReportApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mcodes/report/${queryArg.deviceName}/full`,
                    params: { branding: queryArg.branding },
                }),
                providesTags: ['Report'],
            }),
            reportGetSelectedMcodesReport: build.query<
                ReportGetSelectedMcodesReportApiResponse,
                ReportGetSelectedMcodesReportApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/mcodes/report/${queryArg.deviceName}`,
                    params: {
                        branding: queryArg.branding,
                        codes: queryArg.codes,
                    },
                }),
                providesTags: ['Report'],
            }),
            organizationLicensesAdminGetEntriesAsAdmin: build.query<
                OrganizationLicensesAdminGetEntriesAsAdminApiResponse,
                OrganizationLicensesAdminGetEntriesAsAdminApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/licensing/admin/licenses/admin-all-entries`,
                    params: {
                        PageNumber: queryArg.pageNumber,
                        PageSize: queryArg.pageSize,
                        ColumnName: queryArg.columnName,
                        Direction: queryArg.direction,
                        textFilter: queryArg.textFilter,
                        startDate: queryArg.startDate,
                        endDate: queryArg.endDate,
                    },
                }),
                providesTags: ['Licensing'],
            }),
            organizationLicensesAdminGetAllLicenseTypes: build.query<
                OrganizationLicensesAdminGetAllLicenseTypesApiResponse,
                OrganizationLicensesAdminGetAllLicenseTypesApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/licensing/admin/licenses/all-license-types`,
                    params: {
                        PageNumber: queryArg.pageNumber,
                        PageSize: queryArg.pageSize,
                        textFilter: queryArg.textFilter,
                    },
                }),
                providesTags: ['Licensing'],
            }),
            organizationLicensesAdminGetLicenseById: build.query<
                OrganizationLicensesAdminGetLicenseByIdApiResponse,
                OrganizationLicensesAdminGetLicenseByIdApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/licensing/admin/licenses/${queryArg.id}`,
                }),
                providesTags: ['Licensing'],
            }),
            organizationLicensesAdminUpdateLicenseType: build.mutation<
                OrganizationLicensesAdminUpdateLicenseTypeApiResponse,
                OrganizationLicensesAdminUpdateLicenseTypeApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/licensing/admin/licenses/${queryArg.licenseId}`,
                    method: 'PUT',
                    body: queryArg.updateLicenseTypeDto,
                }),
                invalidatesTags: ['Licensing'],
            }),
            organizationLicensesAdminDeleteLicenseType: build.mutation<
                OrganizationLicensesAdminDeleteLicenseTypeApiResponse,
                OrganizationLicensesAdminDeleteLicenseTypeApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/licensing/admin/licenses/${queryArg.licenseId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Licensing'],
            }),
            organizationLicensesAdminAddNewLicenseType: build.mutation<
                OrganizationLicensesAdminAddNewLicenseTypeApiResponse,
                OrganizationLicensesAdminAddNewLicenseTypeApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/licensing/admin/licenses/add-new-license-type`,
                    method: 'POST',
                    body: queryArg.updateLicenseTypeDto,
                }),
                invalidatesTags: ['Licensing'],
            }),
            organizationLicensesAdminGetLicenseTypeById: build.query<
                OrganizationLicensesAdminGetLicenseTypeByIdApiResponse,
                OrganizationLicensesAdminGetLicenseTypeByIdApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/licensing/admin/licenses/license-type/${queryArg.id}`,
                }),
                providesTags: ['Licensing'],
            }),
            organizationLicensesAdminDownloadReportLicensesExcel: build.query<
                OrganizationLicensesAdminDownloadReportLicensesExcelApiResponse,
                OrganizationLicensesAdminDownloadReportLicensesExcelApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/licensing/admin/licenses/download-excel-licenses`,
                    params: { textFilter: queryArg.textFilter },
                }),
                providesTags: ['Licensing'],
            }),
            organizationLicensesAdminGetCombinedData: build.query<
                OrganizationLicensesAdminGetCombinedDataApiResponse,
                OrganizationLicensesAdminGetCombinedDataApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/licensing/admin/licenses/combined-data`,
                    params: {
                        PageNumber: queryArg.pageNumber,
                        PageSize: queryArg.pageSize,
                        textFilter: queryArg.textFilter,
                        startDate: queryArg.startDate,
                        endDate: queryArg.endDate,
                    },
                }),
                providesTags: ['Licensing'],
            }),
            organizationLicensesAdminDownloadCombinedDataExcel: build.query<
                OrganizationLicensesAdminDownloadCombinedDataExcelApiResponse,
                OrganizationLicensesAdminDownloadCombinedDataExcelApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/licensing/admin/licenses/download-combined-data-excel`,
                    params: {
                        textFilter: queryArg.textFilter,
                        startDate: queryArg.startDate,
                        endDate: queryArg.endDate,
                    },
                }),
                providesTags: ['Licensing'],
            }),
            organizationLicensesAdminOrganizationsDropDownList: build.query<
                OrganizationLicensesAdminOrganizationsDropDownListApiResponse,
                OrganizationLicensesAdminOrganizationsDropDownListApiArg
            >({
                query: () => ({
                    url: `/api/licensing/admin/licenses/organizations-dropdown-list`,
                }),
                providesTags: ['Licensing'],
            }),
            organizationLicensesGetEntriesByOrganization: build.query<
                OrganizationLicensesGetEntriesByOrganizationApiResponse,
                OrganizationLicensesGetEntriesByOrganizationApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/licensing/organization/${queryArg.organizationId}/licenses`,
                    params: {
                        PageNumber: queryArg.pageNumber,
                        PageSize: queryArg.pageSize,
                        ColumnName: queryArg.columnName,
                        Direction: queryArg.direction,
                        textFilter: queryArg.textFilter,
                        startDate: queryArg.startDate,
                        endDate: queryArg.endDate,
                    },
                }),
                providesTags: ['Licensing'],
            }),
            organizationLicensesGetLicenseTypesByOrganization: build.query<
                OrganizationLicensesGetLicenseTypesByOrganizationApiResponse,
                OrganizationLicensesGetLicenseTypesByOrganizationApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/licensing/organization/${queryArg.organizationId}/licenses/license-types`,
                }),
                providesTags: ['Licensing'],
            }),
            organizationLicensesCreateLicense: build.mutation<
                OrganizationLicensesCreateLicenseApiResponse,
                OrganizationLicensesCreateLicenseApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/licensing/organization/${queryArg.organizationId}/licenses/create`,
                    method: 'POST',
                    body: queryArg.licenseRequestDto,
                }),
                invalidatesTags: ['Licensing'],
            }),
            organizationLicensesGetLicenseById: build.query<
                OrganizationLicensesGetLicenseByIdApiResponse,
                OrganizationLicensesGetLicenseByIdApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/licensing/organization/${queryArg.organizationId}/licenses/${queryArg.id}`,
                }),
                providesTags: ['Licensing'],
            }),
            organizationLicensesGenerateLicenses: build.query<
                OrganizationLicensesGenerateLicensesApiResponse,
                OrganizationLicensesGenerateLicensesApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/licensing/organization/${queryArg.organizationId}/licenses/generate-license`,
                    params: { id: queryArg.id },
                }),
                providesTags: ['Licensing'],
            }),
            organizationLicensesGenerateSingleLicense: build.query<
                OrganizationLicensesGenerateSingleLicenseApiResponse,
                OrganizationLicensesGenerateSingleLicenseApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/licensing/organization/${queryArg.organizationId}/licenses/${queryArg.licenseId}/serial/${queryArg.serialNumber}`,
                }),
                providesTags: ['Licensing'],
            }),
            organizationLicensesGetLicenseTypeById: build.query<
                OrganizationLicensesGetLicenseTypeByIdApiResponse,
                OrganizationLicensesGetLicenseTypeByIdApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/licensing/organization/${queryArg.organizationId}/licenses/license-type/${queryArg.id}`,
                }),
                providesTags: ['Licensing'],
            }),
            auditLogGetEntriesByOrganization: build.query<
                AuditLogGetEntriesByOrganizationApiResponse,
                AuditLogGetEntriesByOrganizationApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/codedm/organization/${queryArg.organizationId}/audit-log`,
                }),
                providesTags: ['CodeDM'],
            }),
            auditLogGetEntriesByDevice: build.query<
                AuditLogGetEntriesByDeviceApiResponse,
                AuditLogGetEntriesByDeviceApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/codedm/organization/${queryArg.organizationId}/audit-log/device/${queryArg.serialNumber}`,
                }),
                providesTags: ['CodeDM'],
            }),
            codeDmConfigurationLibraryPostDeviceConfiguration: build.mutation<
                CodeDmConfigurationLibraryPostDeviceConfigurationApiResponse,
                CodeDmConfigurationLibraryPostDeviceConfigurationApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/codedm/organization/${queryArg.organizationId}/configuration/device/${queryArg.deviceName}/name/${queryArg.name}`,
                    method: 'POST',
                    body: queryArg.body,
                }),
                invalidatesTags: ['CodeDM'],
            }),
            codeDmConfigurationLibraryGetDeviceConfigurations: build.query<
                CodeDmConfigurationLibraryGetDeviceConfigurationsApiResponse,
                CodeDmConfigurationLibraryGetDeviceConfigurationsApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/codedm/organization/${queryArg.organizationId}/configuration`,
                }),
                providesTags: ['CodeDM'],
            }),
            codeDmConfigurationLibraryDeleteDeviceConfiguration: build.mutation<
                CodeDmConfigurationLibraryDeleteDeviceConfigurationApiResponse,
                CodeDmConfigurationLibraryDeleteDeviceConfigurationApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/codedm/organization/${queryArg.organizationId}/configuration/device/${queryArg.device}/name/${queryArg.name}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['CodeDM'],
            }),
            codeDmOrganizationGetLocations: build.query<
                CodeDmOrganizationGetLocationsApiResponse,
                CodeDmOrganizationGetLocationsApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/codedm/organization/${queryArg.organizationId}/locations`,
                }),
                providesTags: ['CodeDM'],
            }),
            codeDmOrganizationCreateLocation: build.mutation<
                CodeDmOrganizationCreateLocationApiResponse,
                CodeDmOrganizationCreateLocationApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/codedm/organization/${queryArg.organizationId}/location/${queryArg.location}`,
                    method: 'POST',
                }),
                invalidatesTags: ['CodeDM'],
            }),
            codeDmOrganizationDeleteLocation: build.mutation<
                CodeDmOrganizationDeleteLocationApiResponse,
                CodeDmOrganizationDeleteLocationApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/codedm/organization/${queryArg.organizationId}/location/${queryArg.location}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['CodeDM'],
            }),
            codeDmOrganizationPostDeviceConfigurationFile: build.mutation<
                CodeDmOrganizationPostDeviceConfigurationFileApiResponse,
                CodeDmOrganizationPostDeviceConfigurationFileApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/codedm/organization/${queryArg.organizationId}/location/${queryArg.location}/device/${queryArg.device}/configuration/file`,
                    method: 'POST',
                    body: queryArg.body,
                }),
                invalidatesTags: ['CodeDM'],
            }),
            codeDmOrganizationPostDeviceConfigurationCfg: build.mutation<
                CodeDmOrganizationPostDeviceConfigurationCfgApiResponse,
                CodeDmOrganizationPostDeviceConfigurationCfgApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/codedm/organization/${queryArg.organizationId}/location/${queryArg.location}/device/${queryArg.device}/configuration/cfg`,
                    method: 'POST',
                    body: queryArg.cfgFile,
                }),
                invalidatesTags: ['CodeDM'],
            }),
            codeDmOrganizationGetFirmwareFilesOfDeviceInLocation: build.query<
                CodeDmOrganizationGetFirmwareFilesOfDeviceInLocationApiResponse,
                CodeDmOrganizationGetFirmwareFilesOfDeviceInLocationApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/codedm/organization/${queryArg.organizationId}/location/${queryArg.location}/device/${queryArg.device}/firmware`,
                }),
                providesTags: ['CodeDM'],
            }),
            codeDmOrganizationPostDeviceFirmwareFile: build.mutation<
                CodeDmOrganizationPostDeviceFirmwareFileApiResponse,
                CodeDmOrganizationPostDeviceFirmwareFileApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/codedm/organization/${queryArg.organizationId}/location/${queryArg.location}/device/${queryArg.device}/firmware/file`,
                    method: 'POST',
                    body: queryArg.body,
                }),
                invalidatesTags: ['CodeDM'],
            }),
            codeDmOrganizationPostDeviceFirmwareVersion: build.mutation<
                CodeDmOrganizationPostDeviceFirmwareVersionApiResponse,
                CodeDmOrganizationPostDeviceFirmwareVersionApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/codedm/organization/${queryArg.organizationId}/location/${queryArg.location}/device/${queryArg.device}/firmware/version`,
                    method: 'POST',
                    body: queryArg.firmwareVersion,
                }),
                invalidatesTags: ['CodeDM'],
            }),
            codeDmOrganizationGetDeviceStats: build.query<
                CodeDmOrganizationGetDeviceStatsApiResponse,
                CodeDmOrganizationGetDeviceStatsApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/codedm/organization/${queryArg.organizationId}/devices/totals`,
                }),
                providesTags: ['CodeDM'],
            }),
            codeDmOrganizationGetDevicesPage: build.query<
                CodeDmOrganizationGetDevicesPageApiResponse,
                CodeDmOrganizationGetDevicesPageApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/codedm/organization/${queryArg.organizationId}/devices`,
                    params: {
                        PageNumber: queryArg.pageNumber,
                        PageSize: queryArg.pageSize,
                        ColumnName: queryArg.columnName,
                        Direction: queryArg.direction,
                        textFilter: queryArg.textFilter,
                        onlyBadBattery: queryArg.onlyBadBattery,
                        onlyOffline: queryArg.onlyOffline,
                        onlyWarrantyExpired: queryArg.onlyWarrantyExpired,
                    },
                }),
                providesTags: ['CodeDM'],
            }),
            codeDmOrganizationDownloadDevices: build.query<
                CodeDmOrganizationDownloadDevicesApiResponse,
                CodeDmOrganizationDownloadDevicesApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/codedm/organization/${queryArg.organizationId}/devices/download`,
                }),
                providesTags: ['CodeDM'],
            }),
            codeDmPublicInfoGetDefaultCodeRuleFiles: build.query<
                CodeDmPublicInfoGetDefaultCodeRuleFilesApiResponse,
                CodeDmPublicInfoGetDefaultCodeRuleFilesApiArg
            >({
                query: () => ({ url: `/api/codedm/default-code-rules` }),
                providesTags: ['CodeDM'],
            }),
            codeDmPublicInfoGetAvailableFirmwareForDevice: build.query<
                CodeDmPublicInfoGetAvailableFirmwareForDeviceApiResponse,
                CodeDmPublicInfoGetAvailableFirmwareForDeviceApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/codedm/device/${queryArg.device}/available-firmware`,
                }),
                providesTags: ['CodeDM'],
            }),
            resultsGetResultBySerialNumber: build.query<
                ResultsGetResultBySerialNumberApiResponse,
                ResultsGetResultBySerialNumberApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/cleanliness/Results/serialnumber/${queryArg.serialNumber}`,
                }),
                providesTags: ['Cleanliness Results'],
            }),
            resultsGetResultByImagerFuseId: build.query<
                ResultsGetResultByImagerFuseIdApiResponse,
                ResultsGetResultByImagerFuseIdApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/cleanliness/Results/imager/${queryArg.imagerFuseId}`,
                }),
                providesTags: ['Cleanliness Results'],
            }),
            resultsGetResultsByDeviceName: build.query<
                ResultsGetResultsByDeviceNameApiResponse,
                ResultsGetResultsByDeviceNameApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/cleanliness/Results/device/${queryArg.deviceName}`,
                }),
                providesTags: ['Cleanliness Results'],
            }),
            resultsGetResultsByDateRange: build.query<
                ResultsGetResultsByDateRangeApiResponse,
                ResultsGetResultsByDateRangeApiArg
            >({
                query: (queryArg) => ({
                    url: `/api/cleanliness/Results/date`,
                    params: {
                        startDate: queryArg.startDate,
                        endDate: queryArg.endDate,
                    },
                }),
                providesTags: ['Cleanliness Results'],
            }),
        }),
        overrideExisting: false,
    })
export { injectedRtkApi as codeWebApi }
export type GoCodeIndexApiResponse = unknown
export type GoCodeIndexApiArg = {
    body: {
        data?: string | null
        rows?: number
        cols?: number
        pixelsPerModule?: number
        pixelsPerInch?: number
        correctionLevel?: number
        growMode?: number
        bleedScale?: number
        autoShrink?: number
        invertedImage?: number
        encodingId?: number
    }
}
export type ConfigsGetAllConfigsApiResponse = /** status 200  */ Config[]
export type ConfigsGetAllConfigsApiArg = void
export type ConfigsGetAllConfigsByCidApiResponse = /** status 200  */ Config[]
export type ConfigsGetAllConfigsByCidApiArg = {
    cid: number
}
export type ConfigsGetConfigByIdApiResponse = /** status 200  */ Config
export type ConfigsGetConfigByIdApiArg = {
    id: number
}
export type ConfigsCreateApiResponse = unknown
export type ConfigsCreateApiArg = {
    collection?: any[]
}
export type ConfigsEditApiResponse = unknown
export type ConfigsEditApiArg = {
    id?: number
    collection?: any[]
}
export type ConfigsDeleteApiResponse = unknown
export type ConfigsDeleteApiArg = {
    id?: number
    collection?: any[]
}
export type MdlSaveMDlDataInDbApiResponse = unknown
export type MdlSaveMDlDataInDbApiArg = {
    body: {
        DomainId?: string | null
        HostName?: string | null
        LicenseType?: number
    }
}
export type ReadersGetReadersListApiResponse = /** status 200  */ ReaderDto[]
export type ReadersGetReadersListApiArg = {
    customerid: string
    connectstatus: number
}
export type ReadersGetReaderByIdApiResponse = /** status 200  */ ReaderDto
export type ReadersGetReaderByIdApiArg = {
    id: number
}
export type ReadersEditReaderApiResponse = /** status 200  */ ReaderDto
export type ReadersEditReaderApiArg = {
    id: number
    readerDto: ReaderDto
}
export type ReadersDeleteReaderApiResponse = unknown
export type ReadersDeleteReaderApiArg = {
    id: number
}
export type ReadersCreateReaderApiResponse = /** status 201  */ ReaderDto
export type ReadersCreateReaderApiArg = {
    customerid: string
    readerDto: ReaderDto
}
export type ServerInfoGetServerInfoApiResponse = /** status 200  */ ServerInfo
export type ServerInfoGetServerInfoApiArg = void
export type PurchaseOrderReferenceCreatePurchaseOrderApiResponse =
    /** status 201  */ string
export type PurchaseOrderReferenceCreatePurchaseOrderApiArg = {
    purchaseOrderRequestDto: PurchaseOrderRequestDto
}
export type PurchaseOrderReferenceGetPurchaseOrderApiResponse =
    /** status 200  */ undefined
export type PurchaseOrderReferenceGetPurchaseOrderApiArg = {
    id: number
}
export type LicensingUserRequestAccessesCreateUserRequestApiResponse =
    /** status 201  */ string
export type LicensingUserRequestAccessesCreateUserRequestApiArg = {
    licensingUserRequestAccessDto: LicensingUserRequestAccessDto
}
export type LicensingUserRequestAccessesGetUserRequestApiResponse =
    /** status 200  */ undefined
export type LicensingUserRequestAccessesGetUserRequestApiArg = {
    email: string
}
export type LicensingUserRequestAccessesDeleteUserRequestApiResponse =
    /** status 204  */ undefined
export type LicensingUserRequestAccessesDeleteUserRequestApiArg = {
    email: string
}
export type AdminGetOrganizationsApiResponse =
    /** status 200  */ OrganizationDto[]
export type AdminGetOrganizationsApiArg = void
export type CortexScanUserGetCortexScanUserApiResponse =
    /** status 200  */ CortexscanUserResponseDto
export type CortexScanUserGetCortexScanUserApiArg = {
    email?: string
}
export type CortexScanUserUpdateCortexScanUserApiResponse =
    /** status 201  */ CortexscanUserResponseDto
export type CortexScanUserUpdateCortexScanUserApiArg = {
    cortexscanUserPostDto: CortexscanUserPostDto
}
export type CortexScanUserDeleteCortexScanUserApiResponse =
    /** status 204  */ undefined
export type CortexScanUserDeleteCortexScanUserApiArg = {
    email?: string
}
export type CountryGetCountriesApiResponse = /** status 200  */ CountryDto[]
export type CountryGetCountriesApiArg = void
export type GraphOrganizationUniqueDevicesByTimeApiResponse =
    /** status 200  */ ChartJsObjectData
export type GraphOrganizationUniqueDevicesByTimeApiArg = {
    organizationId: string
}
export type GraphGlobalUniqueDevicesByTimeApiResponse =
    /** status 200  */ ChartJsObjectData
export type GraphGlobalUniqueDevicesByTimeApiArg = void
export type GraphOrganizationMostFrequentDeviceModelsApiResponse =
    /** status 200  */ ChartJsPrimitiveData
export type GraphOrganizationMostFrequentDeviceModelsApiArg = {
    organizationId: string
}
export type GraphGlobalMostFrequentDeviceModelsApiResponse =
    /** status 200  */ ChartJsPrimitiveData
export type GraphGlobalMostFrequentDeviceModelsApiArg = void
export type GraphOrganizationTotalDevicesByOsApiResponse =
    /** status 200  */ ChartJsPrimitiveData
export type GraphOrganizationTotalDevicesByOsApiArg = {
    organizationId: string
}
export type GraphGlobalTotalDevicesByOsApiResponse =
    /** status 200  */ ChartJsPrimitiveData
export type GraphGlobalTotalDevicesByOsApiArg = void
export type GraphOrganizationUniqueDevicesByLocationApiResponse =
    /** status 200  */ BubbleMapPoint[]
export type GraphOrganizationUniqueDevicesByLocationApiArg = {
    organizationId: string
}
export type GraphGlobalUniqueDevicesByLocationApiResponse =
    /** status 200  */ BubbleMapPoint[]
export type GraphGlobalUniqueDevicesByLocationApiArg = void
export type GraphOrganizationLicenseCountsApiResponse =
    /** status 200  */ LicenseCounts
export type GraphOrganizationLicenseCountsApiArg = {
    organizationId: string
}
export type GraphGlobalLicenseCountsApiResponse =
    /** status 200  */ LicenseCounts
export type GraphGlobalLicenseCountsApiArg = void
export type GroupGetGroupsApiResponse = /** status 200  */ GroupDto[]
export type GroupGetGroupsApiArg = void
export type GroupCreateGroupApiResponse = /** status 201  */ GroupDto
export type GroupCreateGroupApiArg = {
    createGroupRequest: CreateGroupRequest
}
export type GroupGetGroupByIdApiResponse = /** status 200  */ GroupDto
export type GroupGetGroupByIdApiArg = {
    id: number
}
export type GroupDeleteGroupApiResponse = /** status 204  */ undefined
export type GroupDeleteGroupApiArg = {
    id: number
    deleteGroupRequest: DeleteGroupRequest
}
export type GroupRenameGroupApiResponse = /** status 204  */ undefined
export type GroupRenameGroupApiArg = {
    id: number
    renameGroupRequest: RenameGroupRequest
}
export type GroupSetGroupProductsApiResponse = /** status 204  */ undefined
export type GroupSetGroupProductsApiArg = {
    id: number
    productListRequest: ProductListRequest
}
export type GroupJoinGroupApiResponse = /** status 204  */ undefined
export type GroupJoinGroupApiArg = {
    id: number
}
export type MarketingAllApiResponse = /** status 200  */ AdDto[]
export type MarketingAllApiArg = void
export type MarketingCreateAdApiResponse = /** status 200  */ number
export type MarketingCreateAdApiArg = {
    ad: Ad
}
export type MarketingIndexApiResponse = /** status 200  */ AdDto
export type MarketingIndexApiArg = {
    id: number
}
export type MarketingUpdateAdApiResponse = /** status 204  */ undefined
export type MarketingUpdateAdApiArg = {
    id: number
    ad: Ad
}
export type MarketingDestroyApiResponse = unknown
export type MarketingDestroyApiArg = {
    id: number
}
export type OrganizationGetOrganizationApiResponse =
    /** status 200  */ OrganizationDto
export type OrganizationGetOrganizationApiArg = {
    organizationId: string
}
export type OrganizationGetUsersApiResponse = /** status 200  */ OtherUserDto[]
export type OrganizationGetUsersApiArg = {
    organizationId: string
}
export type ProductGetProductsApiResponse = /** status 200  */ ProductDto[]
export type ProductGetProductsApiArg = void
export type ProductGetProductByIdApiResponse = /** status 200  */ ProductDto
export type ProductGetProductByIdApiArg = {
    id: number
}
export type RegistrationRegisterUserApiResponse = /** status 201  */ undefined
export type RegistrationRegisterUserApiArg = {
    userRegistrationRequest: UserRegistrationRequest
}
export type RegistrationGetRemainingRegistrationTasksApiResponse =
    /** status 200  */ RegistrationTask[]
export type RegistrationGetRemainingRegistrationTasksApiArg = {
    registrationApp?: RegistrationApp
}
export type RegistrationRegisterOrganizationApiResponse = unknown
export type RegistrationRegisterOrganizationApiArg = {
    companyName?: string
}
export type SalesforceGetCasesByEmailApiResponse = /** status 200  */
    | CaseResponse
    | /** status 204  */ undefined
export type SalesforceGetCasesByEmailApiArg = {
    /** The email to search for. */
    email: string
}
export type SalesforceGetCasesForAdminApiResponse = /** status 200  */
    | CaseResponse
    | /** status 204  */ undefined
export type SalesforceGetCasesForAdminApiArg = void
export type SalesforceGetCasesByIdApiResponse = /** status 200  */
    | GetByIdCaseResponse
    | /** status 204  */ undefined
export type SalesforceGetCasesByIdApiArg = {
    /** The ID of the case to retrieve. */
    id: string
}
export type SalesforceVerifyEmailApiResponse = /** status 200  */
    | VerifyEmailResponse
    | /** status 204  */ undefined
export type SalesforceVerifyEmailApiArg = {
    /** The email to verify. */
    email: string
}
export type SalesforceCreateCaseApiResponse = /** status 200  */
    | VerifyEmailResponse
    | /** status 204  */ undefined
export type SalesforceCreateCaseApiArg = {
    /** The case model to create. */
    createCaseModel: CreateCaseModel
}
export type SalesforceUpdateCaseApiResponse = /** status 200  */
    | VerifyEmailResponse
    | /** status 204  */ undefined
export type SalesforceUpdateCaseApiArg = {
    body: any
}
export type SalesforceDeleteCasesByIdApiResponse = /** status 200  */
    | CaseResponse
    | /** status 204  */ undefined
export type SalesforceDeleteCasesByIdApiArg = {
    /** The ID of the case to retrieve. */
    id: string
}
export type UserGetAllPendingUsersApiResponse = /** status 200  */ UsersPageDto
export type UserGetAllPendingUsersApiArg = {
    pageNumber: number
    pageSize: number
    textFilter?: string | null
}
export type UserGetAllUsersApiResponse = /** status 200  */ UsersPageDto
export type UserGetAllUsersApiArg = {
    pageNumber: number
    pageSize: number
    textFilter?: string | null
}
export type UserGetFullListOfUsersApiResponse = /** status 200  */ UsersPageDto
export type UserGetFullListOfUsersApiArg = {
    pageNumber: number
    pageSize: number
    textFilter?: string | null
}
export type UserDownloadFullListOfUsersExcelApiResponse = unknown
export type UserDownloadFullListOfUsersExcelApiArg = {
    textFilter?: string | null
}
export type UserGetCurrentUserApiResponse = /** status 200  */ UserDto
export type UserGetCurrentUserApiArg = void
export type UserUpdateCurrentUserApiResponse = unknown
export type UserUpdateCurrentUserApiArg = {
    updateUserDto: UpdateUserDto
}
export type UserUpdateUserApiResponse = unknown
export type UserUpdateUserApiArg = {
    userId: string
    updateUserDto: UpdateUserDto
}
export type UserDeleteUserApiResponse = /** status 204  */ undefined
export type UserDeleteUserApiArg = {
    userId: string
}
export type UserAddUserApiResponse = /** status 201  */ undefined
export type UserAddUserApiArg = {
    companyName?: string
    userRegistrationRequest: UserRegistrationRequest
}
export type McodeAdminCreateOrEditProductApiResponse = unknown
export type McodeAdminCreateOrEditProductApiArg = {
    productDto: ProductDto
}
export type McodesGetConfigurableDevicesApiResponse =
    /** status 200  */ ProductDto[]
export type McodesGetConfigurableDevicesApiArg = {
    brand?: Branding | null
}
export type McodesGetDeviceCategoriesApiResponse = /** status 200  */ string[]
export type McodesGetDeviceCategoriesApiArg = {
    deviceName: string
}
export type McodesGetCategorySettingsApiResponse =
    /** status 200  */ SettingsDto[]
export type McodesGetCategorySettingsApiArg = {
    deviceName: string
    category?: string
}
export type ReportGetFullMcodeReportApiResponse = unknown
export type ReportGetFullMcodeReportApiArg = {
    deviceName: string
    branding?: Branding
}
export type ReportGetSelectedMcodesReportApiResponse = unknown
export type ReportGetSelectedMcodesReportApiArg = {
    deviceName: string
    branding?: Branding
    codes?: string[]
}
export type OrganizationLicensesAdminGetEntriesAsAdminApiResponse =
    /** status 200  */ LicensesPageDto
export type OrganizationLicensesAdminGetEntriesAsAdminApiArg = {
    pageNumber: number
    pageSize: number
    columnName?: string | null
    direction?: SortDirection | null
    textFilter?: string | null
    startDate?: string | null
    endDate?: string | null
}
export type OrganizationLicensesAdminGetAllLicenseTypesApiResponse =
    /** status 200  */ LicenseTypesPageDto
export type OrganizationLicensesAdminGetAllLicenseTypesApiArg = {
    pageNumber: number
    pageSize: number
    textFilter?: string | null
}
export type OrganizationLicensesAdminGetLicenseByIdApiResponse =
    /** status 200  */ LicenseDto
export type OrganizationLicensesAdminGetLicenseByIdApiArg = {
    id: number
}
export type OrganizationLicensesAdminUpdateLicenseTypeApiResponse = unknown
export type OrganizationLicensesAdminUpdateLicenseTypeApiArg = {
    licenseId: number
    updateLicenseTypeDto: UpdateLicenseTypeDto
}
export type OrganizationLicensesAdminDeleteLicenseTypeApiResponse =
    /** status 204  */ undefined
export type OrganizationLicensesAdminDeleteLicenseTypeApiArg = {
    licenseId: number
}
export type OrganizationLicensesAdminAddNewLicenseTypeApiResponse =
    /** status 201  */ undefined
export type OrganizationLicensesAdminAddNewLicenseTypeApiArg = {
    updateLicenseTypeDto: UpdateLicenseTypeDto
}
export type OrganizationLicensesAdminGetLicenseTypeByIdApiResponse =
    /** status 200  */ SingleLicenseTypeDto
export type OrganizationLicensesAdminGetLicenseTypeByIdApiArg = {
    id: number
}
export type OrganizationLicensesAdminDownloadReportLicensesExcelApiResponse =
    unknown
export type OrganizationLicensesAdminDownloadReportLicensesExcelApiArg = {
    textFilter?: string | null
}
export type OrganizationLicensesAdminGetCombinedDataApiResponse =
    /** status 200  */ LicensesPageDto
export type OrganizationLicensesAdminGetCombinedDataApiArg = {
    pageNumber: number
    pageSize: number
    textFilter?: string | null
    startDate?: string | null
    endDate?: string | null
}
export type OrganizationLicensesAdminDownloadCombinedDataExcelApiResponse =
    unknown
export type OrganizationLicensesAdminDownloadCombinedDataExcelApiArg = {
    textFilter?: string | null
    startDate?: string | null
    endDate?: string | null
}
export type OrganizationLicensesAdminOrganizationsDropDownListApiResponse =
    /** status 200  */ undefined
export type OrganizationLicensesAdminOrganizationsDropDownListApiArg = void
export type OrganizationLicensesGetEntriesByOrganizationApiResponse =
    /** status 200  */ LicensesPageDto
export type OrganizationLicensesGetEntriesByOrganizationApiArg = {
    organizationId: string
    pageNumber: number
    pageSize: number
    columnName?: string | null
    direction?: SortDirection | null
    textFilter?: string | null
    startDate?: string | null
    endDate?: string | null
}
export type OrganizationLicensesGetLicenseTypesByOrganizationApiResponse =
    /** status 200  */ LicenseTypeDto[]
export type OrganizationLicensesGetLicenseTypesByOrganizationApiArg = {
    organizationId: string
}
export type OrganizationLicensesCreateLicenseApiResponse =
    /** status 201  */ number
export type OrganizationLicensesCreateLicenseApiArg = {
    organizationId: string
    licenseRequestDto: LicenseRequestDto
}
export type OrganizationLicensesGetLicenseByIdApiResponse =
    /** status 200  */ LicenseDto
export type OrganizationLicensesGetLicenseByIdApiArg = {
    id: number
    organizationId: string
}
export type OrganizationLicensesGenerateLicensesApiResponse =
    /** status 200  */ LicenseDto
export type OrganizationLicensesGenerateLicensesApiArg = {
    id?: number
    organizationId: string
}
export type OrganizationLicensesGenerateSingleLicenseApiResponse =
    /** status 200  */ Blob
export type OrganizationLicensesGenerateSingleLicenseApiArg = {
    licenseId: number
    serialNumber: string
    organizationId: string
}
export type OrganizationLicensesGetLicenseTypeByIdApiResponse =
    /** status 200  */ SingleLicenseTypeDto
export type OrganizationLicensesGetLicenseTypeByIdApiArg = {
    id: number
    organizationId: string
}
export type AuditLogGetEntriesByOrganizationApiResponse =
    /** status 200  */ AuditLogEntry[]
export type AuditLogGetEntriesByOrganizationApiArg = {
    organizationId: string
}
export type AuditLogGetEntriesByDeviceApiResponse =
    /** status 200  */ AuditLogEntry[]
export type AuditLogGetEntriesByDeviceApiArg = {
    organizationId: string
    serialNumber: string
}
export type CodeDmConfigurationLibraryPostDeviceConfigurationApiResponse =
    unknown
export type CodeDmConfigurationLibraryPostDeviceConfigurationApiArg = {
    organizationId: string
    name: string
    deviceName: string
    body: string[]
}
export type CodeDmConfigurationLibraryGetDeviceConfigurationsApiResponse =
    /** status 200  */ CodeDmDeviceConfig[]
export type CodeDmConfigurationLibraryGetDeviceConfigurationsApiArg = {
    organizationId: string
}
export type CodeDmConfigurationLibraryDeleteDeviceConfigurationApiResponse =
    unknown
export type CodeDmConfigurationLibraryDeleteDeviceConfigurationApiArg = {
    organizationId: string
    name: string
    device: string
}
export type CodeDmOrganizationGetLocationsApiResponse =
    /** status 200  */ DmLocation[]
export type CodeDmOrganizationGetLocationsApiArg = {
    organizationId: string
}
export type CodeDmOrganizationCreateLocationApiResponse =
    /** status 200  */ MessageResponse
export type CodeDmOrganizationCreateLocationApiArg = {
    organizationId: string
    location: string
}
export type CodeDmOrganizationDeleteLocationApiResponse =
    /** status 200  */ MessageResponse
export type CodeDmOrganizationDeleteLocationApiArg = {
    organizationId: string
    location: string
}
export type CodeDmOrganizationPostDeviceConfigurationFileApiResponse =
    /** status 200  */ MessageResponse
export type CodeDmOrganizationPostDeviceConfigurationFileApiArg = {
    organizationId: string
    device: string
    location: string
    body: {
        ContentType?: string | null
        ContentDisposition?: string | null
        Headers?: any[] | null
        Length?: number
        Name?: string | null
        FileName?: string | null
    }
}
export type CodeDmOrganizationPostDeviceConfigurationCfgApiResponse =
    /** status 200  */ MessageResponse
export type CodeDmOrganizationPostDeviceConfigurationCfgApiArg = {
    organizationId: string
    device: string
    location: string
    cfgFile: CfgFile
}
export type CodeDmOrganizationGetFirmwareFilesOfDeviceInLocationApiResponse =
    /** status 200  */ any[]
export type CodeDmOrganizationGetFirmwareFilesOfDeviceInLocationApiArg = {
    organizationId: string
    device: string
    location: string
}
export type CodeDmOrganizationPostDeviceFirmwareFileApiResponse =
    /** status 200  */ MessageResponse
export type CodeDmOrganizationPostDeviceFirmwareFileApiArg = {
    organizationId: string
    device: string
    location: string
    body: {
        ContentType?: string | null
        ContentDisposition?: string | null
        Headers?: any[] | null
        Length?: number
        Name?: string | null
        FileName?: string | null
    }
}
export type CodeDmOrganizationPostDeviceFirmwareVersionApiResponse =
    /** status 200  */ MessageResponse
export type CodeDmOrganizationPostDeviceFirmwareVersionApiArg = {
    organizationId: string
    device: string
    location: string
    firmwareVersion: FirmwareVersion
}
export type CodeDmOrganizationGetDeviceStatsApiResponse =
    /** status 200  */ DeviceHeader
export type CodeDmOrganizationGetDeviceStatsApiArg = {
    organizationId: string
}
export type CodeDmOrganizationGetDevicesPageApiResponse =
    /** status 200  */ Devices
export type CodeDmOrganizationGetDevicesPageApiArg = {
    organizationId: string
    pageNumber: number
    pageSize: number
    columnName?: string | null
    direction?: SortDirection | null
    textFilter?: string | null
    onlyBadBattery?: boolean
    onlyOffline?: boolean
    onlyWarrantyExpired?: boolean
}
export type CodeDmOrganizationDownloadDevicesApiResponse = unknown
export type CodeDmOrganizationDownloadDevicesApiArg = {
    organizationId: string
}
export type CodeDmPublicInfoGetDefaultCodeRuleFilesApiResponse =
    /** status 200  */ string[]
export type CodeDmPublicInfoGetDefaultCodeRuleFilesApiArg = void
export type CodeDmPublicInfoGetAvailableFirmwareForDeviceApiResponse =
    /** status 200  */ FirmwareVersion[]
export type CodeDmPublicInfoGetAvailableFirmwareForDeviceApiArg = {
    device: string
}
export type ResultsGetResultBySerialNumberApiResponse =
    /** status 200  */ ResultDto[]
export type ResultsGetResultBySerialNumberApiArg = {
    serialNumber: string
}
export type ResultsGetResultByImagerFuseIdApiResponse =
    /** status 200  */ ResultDto[]
export type ResultsGetResultByImagerFuseIdApiArg = {
    imagerFuseId: string
}
export type ResultsGetResultsByDeviceNameApiResponse =
    /** status 200  */ ResultDto[]
export type ResultsGetResultsByDeviceNameApiArg = {
    deviceName: string
}
export type ResultsGetResultsByDateRangeApiResponse =
    /** status 200  */ ResultDto[]
export type ResultsGetResultsByDateRangeApiArg = {
    startDate?: string
    endDate?: string
}
export type Config = {
    id?: number
    companyId?: number
    configName?: string
    configString?: string
}
export type ReaderDto = {
    readerId: number
    sn: string
    isLost: boolean
    lastActivityDate?: string | null
    codeMasterId?: string | null
}
export type ProblemDetails = {
    type?: string | null
    title?: string | null
    status?: number | null
    detail?: string | null
    instance?: string | null
    [key: string]: any | null
}
export type ServerInfo = {
    version?: string
    squeakyVersion?: string
}
export type PurchaseOrderRequestDto = {
    orderNumber?: string
    orderDate?: string
    createdBy?: string
    createdFor?: string
}
export type LicensingUserRequestAccessDto = {
    workEmail: string
    status: string
    createdAt?: string
}
export type OtherUserDto = {
    firstName: string
    lastName: string
    email: string
}
export type GroupDto = {
    gid: number
    name: string
    products: number[]
    users: OtherUserDto[]
}
export type OrganizationDto = {
    id: string
    name?: string | null
    groups: GroupDto[]
    hasEpicData: boolean
}
export type CortexscanUserResponseDto = {
    userId?: string
    userType?: string
    name?: string
    email?: string
    phone?: string
    doOptInForAppUpdates?: boolean
    doOptInForEnterpriseUpdates?: boolean
    companyName?: string
    companySize?: string
    role?: string
    industry?: string
    task?: string
}
export type CortexscanUserPostDto = {
    userType?: string | null
    name?: string | null
    email?: string
    phone?: string | null
    doOptInForAppUpdates?: boolean
    doOptInForEnterpriseUpdates?: boolean
    companyName?: string | null
    companySize?: string | null
    role?: string | null
    industry?: string | null
    task?: string | null
}
export type CountryDto = {
    countryCode: string
    name: string
}
export type ChartJsObjectDatum = {
    x: any
    y: any
}
export type ChartJsObjectDataset = {
    label?: string
    data: ChartJsObjectDatum[]
}
export type ChartJsObjectData = {
    datasets: ChartJsObjectDataset[]
}
export type ChartJsPrimitiveDataset = {
    label?: string
    data: number[]
}
export type ChartJsPrimitiveData = {
    labels: string[]
    datasets: ChartJsPrimitiveDataset[]
}
export type BubbleMapPoint = {
    latitude?: number
    longitude?: number
    value?: number
    city?: string
}
export type LicenseCounts = {
    total: number
    usingDecode: number
}
export type CreateGroupRequest = {
    name?: string
}
export type DeleteGroupRequest = {
    replacementGroupId?: number
}
export type RenameGroupRequest = {
    name?: string
}
export type ProductListRequest = {
    productIds?: number[]
}
export type AdDto = {
    adsid?: number
    title?: string
    description?: string
    adsImage?: string
    adsUrl?: string
    adsStartDate?: string
    adsEndDate?: string
    createdOn?: string
    createdBy?: number
    createdByName?: string
    status?: number
}
export type Ad = {
    adsid?: number
    title?: string
    description?: string
    adsImage?: string
    adsUrl?: string
    adsStartDate?: string
    adsEndDate?: string
    createdOn?: string
    createdBy?: number
    createdByName?: string
    status?: number
}
export type ProductTypeType = 'hardware' | 'software' | 'other'
export type FaqDto = {
    question: string
    answer: string
}
export type DocumentDto = {
    title: string
    url: string
}
export type ProductDto = {
    name: string
    title: string
    description: string
    short: string
    productType: ProductTypeType
    productImage: string
    createdOn?: string
    updatedOn?: string
    faq: FaqDto[]
    documents: DocumentDto[]
    productId: number
    isDiscontinued: boolean
}
export type UserRegistrationRequest = {
    firstName: string
    lastName: string
    workEmail: string
    workPhoneNumber: string
    countryCode: string
}
export type RegistrationTask =
    | 'initial-user-registration'
    | 'organization-setup'
    | 'join-a-group'
    | 'select-products'
export type RegistrationApp = 'my-code' | 'licensing'
export type Attributes = {
    type?: string
    url?: string
}
export type ListCase = {
    attributes?: Attributes
    id?: string
    contactEmail?: string
    status?: string
    createdDate?: string
    subject?: string
}
export type CaseResponse = {
    success?: string
    status?: string
    message?: string
    listCase?: ListCase[]
}
export type GetByIdCaseResponse = {
    status?: string | null
    contactEmail?: string | null
    subject?: string | null
    description?: string | null
    reason?: string | null
    action_Plan__c?: string | null
    priority?: string | null
    origin?: string | null
    model__c?: string | null
    contactId?: string | null
}
export type ConList = {
    attributes?: Attributes
    id?: string
    contactEmail?: string
}
export type VerifyEmailResponse = {
    success?: string
    status?: string
    message?: string
    listCase?: ConList[]
}
export type RecordType = {
    id?: string
    developerName?: string
}
export type CreateCaseModel = {
    status?: string
    contactEmail?: string
    subject?: string
    description?: string
    reason?: string
    plan?: string
    priority?: string
    origin?: string
    model?: string
    contactId?: string
    recordType?: RecordType
}
export type RequestStatus = 'pending' | 'approved' | 'rejected'
export type AllUsersDto = {
    userId: string
    organizationId: string[]
    group: GroupDto
    phone: string
    email: string
    countryCode: string
    lastName: string
    firstName: string
    companyName: string
    countryName: string
    status: RequestStatus
}
export type UsersPageDto = {
    users: AllUsersDto[]
    pageSize?: number
    pageNumber?: number
    totalCount?: number
}
export type UserDto = AllUsersDto & {
    permissions: string[]
}
export type UpdateUserDto = {
    phone?: string | null
    countryCode?: string | null
    lastName?: string | null
    firstName?: string | null
    status?: RequestStatus | null
}
export type Branding = 'codecorp' | 'brady'
export type SettingsDto = {
    code: string
    revision: number
    description: string
    sourceString: string[]
    isDefault: boolean
}
export type LicenseDto = {
    id?: number
    serialNumbers: string[]
    licenseNumber?: number
    purchaseOrderNumber?: string | null
    organizationId?: string
    organizationCountry?: string
    organizationName?: string
    date?: string
}
export type LicensesPageDto = {
    licenses: LicenseDto[]
    pageSize?: number
    pageNumber?: number
    totalCount?: number
}
export type SortDirection = 'ascending' | 'descending'
export type LicenseTypeDto = {
    licenseId?: number
    name?: string
    needPurchaseOrder?: boolean
    isEnabled?: boolean
}
export type LicenseTypesPageDto = {
    licenseTypes: LicenseTypeDto[]
    pageSize?: number
    pageNumber?: number
    totalCount?: number
}
export type UpdateLicenseTypeDto = {
    licenseId?: number
    name?: string | null
    isEnabled?: boolean | null
}
export type SingleLicenseTypeDto = {
    licenseId?: number
    name?: string
    isEnabled?: boolean
}
export type LicenseRequestDto = {
    serialNumbers: string[]
    licenseNumber?: number
    purchaseOrderNumber?: string | null
}
export type AuditLogEntry = {
    organizationId?: string
    initiatorEmail?: string | null
    inEffectSince?: string
    changeType: string
}
export type CodeDmDeviceConfig = {
    name: string
    device: string
    commands: string[]
    creationDate: string
}
export type DmLocation = {
    organizationId: string
    name: string
    id: string
}
export type MessageResponse = {
    message?: string
}
export type CfgFile = {
    cfg?: string
}
export type FirmwareVersion = {
    version: string
}
export type DeviceHeader = {
    allDevices: number
    offline: number
    batteryHealth: number
    warrantyExpirations: number
}
export type DeviceInfo = {
    deviceType: string
    serialNumber: string
    zone: string
    host?: string | null
    lastUpdatedDate: string
    currentConfig?: string | null
    currentFirmware?: string | null
    lastSeen: string
    batteryHealth?: number | null
    warrantyRemaining?: string | null
}
export type Devices = {
    deviceList: DeviceInfo[]
    pageSize?: number
    pageNumber?: number
    totalCount?: number
}
export type ResultDto = {
    passed?: boolean
    imagerFuseId?: string
    serialNumber?: string
    deviceName?: string
    resultTime?: string
    $type: string
}
export const {
    useGoCodeIndexMutation,
    useConfigsGetAllConfigsQuery,
    useConfigsGetAllConfigsByCidQuery,
    useConfigsGetConfigByIdQuery,
    useConfigsCreateMutation,
    useConfigsEditMutation,
    useConfigsDeleteMutation,
    useMdlSaveMDlDataInDbMutation,
    useReadersGetReadersListQuery,
    useReadersGetReaderByIdQuery,
    useReadersEditReaderMutation,
    useReadersDeleteReaderMutation,
    useReadersCreateReaderMutation,
    useServerInfoGetServerInfoQuery,
    usePurchaseOrderReferenceCreatePurchaseOrderMutation,
    usePurchaseOrderReferenceGetPurchaseOrderQuery,
    useLicensingUserRequestAccessesCreateUserRequestMutation,
    useLicensingUserRequestAccessesGetUserRequestQuery,
    useLicensingUserRequestAccessesDeleteUserRequestMutation,
    useAdminGetOrganizationsQuery,
    useCortexScanUserGetCortexScanUserQuery,
    useCortexScanUserUpdateCortexScanUserMutation,
    useCortexScanUserDeleteCortexScanUserMutation,
    useCountryGetCountriesQuery,
    useGraphOrganizationUniqueDevicesByTimeQuery,
    useGraphGlobalUniqueDevicesByTimeQuery,
    useGraphOrganizationMostFrequentDeviceModelsQuery,
    useGraphGlobalMostFrequentDeviceModelsQuery,
    useGraphOrganizationTotalDevicesByOsQuery,
    useGraphGlobalTotalDevicesByOsQuery,
    useGraphOrganizationUniqueDevicesByLocationQuery,
    useGraphGlobalUniqueDevicesByLocationQuery,
    useGraphOrganizationLicenseCountsQuery,
    useGraphGlobalLicenseCountsQuery,
    useGroupGetGroupsQuery,
    useGroupCreateGroupMutation,
    useGroupGetGroupByIdQuery,
    useGroupDeleteGroupMutation,
    useGroupRenameGroupMutation,
    useGroupSetGroupProductsMutation,
    useGroupJoinGroupMutation,
    useMarketingAllQuery,
    useMarketingCreateAdMutation,
    useMarketingIndexQuery,
    useMarketingUpdateAdMutation,
    useMarketingDestroyMutation,
    useOrganizationGetOrganizationQuery,
    useOrganizationGetUsersQuery,
    useProductGetProductsQuery,
    useProductGetProductByIdQuery,
    useRegistrationRegisterUserMutation,
    useRegistrationGetRemainingRegistrationTasksQuery,
    useRegistrationRegisterOrganizationMutation,
    useSalesforceGetCasesByEmailQuery,
    useSalesforceGetCasesForAdminQuery,
    useSalesforceGetCasesByIdQuery,
    useSalesforceVerifyEmailQuery,
    useSalesforceCreateCaseMutation,
    useSalesforceUpdateCaseMutation,
    useSalesforceDeleteCasesByIdMutation,
    useUserGetAllPendingUsersQuery,
    useUserGetAllUsersQuery,
    useUserGetFullListOfUsersQuery,
    useUserDownloadFullListOfUsersExcelQuery,
    useUserGetCurrentUserQuery,
    useUserUpdateCurrentUserMutation,
    useUserUpdateUserMutation,
    useUserDeleteUserMutation,
    useUserAddUserMutation,
    useMcodeAdminCreateOrEditProductMutation,
    useMcodesGetConfigurableDevicesQuery,
    useMcodesGetDeviceCategoriesQuery,
    useMcodesGetCategorySettingsQuery,
    useReportGetFullMcodeReportQuery,
    useReportGetSelectedMcodesReportQuery,
    useOrganizationLicensesAdminGetEntriesAsAdminQuery,
    useOrganizationLicensesAdminGetAllLicenseTypesQuery,
    useOrganizationLicensesAdminGetLicenseByIdQuery,
    useOrganizationLicensesAdminUpdateLicenseTypeMutation,
    useOrganizationLicensesAdminDeleteLicenseTypeMutation,
    useOrganizationLicensesAdminAddNewLicenseTypeMutation,
    useOrganizationLicensesAdminGetLicenseTypeByIdQuery,
    useOrganizationLicensesAdminDownloadReportLicensesExcelQuery,
    useOrganizationLicensesAdminGetCombinedDataQuery,
    useOrganizationLicensesAdminDownloadCombinedDataExcelQuery,
    useOrganizationLicensesAdminOrganizationsDropDownListQuery,
    useOrganizationLicensesGetEntriesByOrganizationQuery,
    useOrganizationLicensesGetLicenseTypesByOrganizationQuery,
    useOrganizationLicensesCreateLicenseMutation,
    useOrganizationLicensesGetLicenseByIdQuery,
    useOrganizationLicensesGenerateLicensesQuery,
    useOrganizationLicensesGenerateSingleLicenseQuery,
    useOrganizationLicensesGetLicenseTypeByIdQuery,
    useAuditLogGetEntriesByOrganizationQuery,
    useAuditLogGetEntriesByDeviceQuery,
    useCodeDmConfigurationLibraryPostDeviceConfigurationMutation,
    useCodeDmConfigurationLibraryGetDeviceConfigurationsQuery,
    useCodeDmConfigurationLibraryDeleteDeviceConfigurationMutation,
    useCodeDmOrganizationGetLocationsQuery,
    useCodeDmOrganizationCreateLocationMutation,
    useCodeDmOrganizationDeleteLocationMutation,
    useCodeDmOrganizationPostDeviceConfigurationFileMutation,
    useCodeDmOrganizationPostDeviceConfigurationCfgMutation,
    useCodeDmOrganizationGetFirmwareFilesOfDeviceInLocationQuery,
    useCodeDmOrganizationPostDeviceFirmwareFileMutation,
    useCodeDmOrganizationPostDeviceFirmwareVersionMutation,
    useCodeDmOrganizationGetDeviceStatsQuery,
    useCodeDmOrganizationGetDevicesPageQuery,
    useCodeDmOrganizationDownloadDevicesQuery,
    useCodeDmPublicInfoGetDefaultCodeRuleFilesQuery,
    useCodeDmPublicInfoGetAvailableFirmwareForDeviceQuery,
    useResultsGetResultBySerialNumberQuery,
    useResultsGetResultByImagerFuseIdQuery,
    useResultsGetResultsByDeviceNameQuery,
    useResultsGetResultsByDateRangeQuery,
} = injectedRtkApi
