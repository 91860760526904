import clsx from 'clsx'
import { DeviceSelectorCard } from './device-selector-card'
import {
    Branding,
    useMcodesGetConfigurableDevicesQuery,
} from '@codecorp/web-api-wrapper'

export const DeviceSelector = () => {
    const { data: filteredProducts = [] } =
        useMcodesGetConfigurableDevicesQuery({
            brand: 'codecorp' satisfies Branding,
        })

    return (
        <section
            className={clsx(
                'mt-4 w-full transition-opacity duration-500',
                filteredProducts.length > 0 ? 'opacity-100' : 'opacity-0',
            )}
        >
            <div className="grid h-full w-full grid-cols-1 gap-4 sm:grid-cols-4">
                {filteredProducts.map((device) => (
                    <DeviceSelectorCard
                        key={device.name}
                        device={device}
                    />
                ))}
            </div>
        </section>
    )
}
