import { Button, useModal } from '@codecorp/components'
import { CategoryAccordion } from '../components/category-accordion'
import { SearchBar } from '../components/search-bar'
import { SettingsList } from '../components/settings-list'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useProgressData } from '../providers/progress-data-provider'
import {
    ProductDto,
    useMcodesGetDeviceCategoriesQuery,
} from '@codecorp/web-api-wrapper'
import { BarcodeDialog } from '../components/barcode-dialog'
import scrollIntoView from 'scroll-into-view-if-needed'

export interface SettingsSelectionPageProps {
    selectedDevice: ProductDto
}

export const SettingsSelectionPage = ({
    selectedDevice,
}: SettingsSelectionPageProps) => {
    const [searchTerm, setSearchTerm] = useState('')
    const { data: allCategories } = useMcodesGetDeviceCategoriesQuery({
        deviceName: selectedDevice.name,
    })
    const { show } = useModal()

    const { state, dispatch } = useProgressData()
    const [filteredCategories, setFilteredCategories] = useState(
        state.categories,
    )

    const scrollToRef = useRef<HTMLElement>(null)

    // Since it's not actually changing routes or loading new pages
    // this ensures the settings "page" will start at the top
    useEffect(() => {
        if (scrollToRef.current) scrollIntoView(scrollToRef.current)
    }, [])

    useEffect(() => {
        setFilteredCategories(state.categories)
    }, [state.categories])

    const handleSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value.toLowerCase())
    }, [])

    const handleRestart = useCallback(() => {
        // eslint-disable-next-line no-alert
        if (window.confirm('Are sure you want to reset?')) {
            dispatch({ type: 'userRestarted' })
        }
    }, [dispatch])

    const handleBack = useCallback(() => {
        dispatch({ type: 'backButtonPressed' })
    }, [dispatch])

    return (
        <section
            ref={scrollToRef}
            className="mt-8 min-h-screen"
        >
            <div className="flex flex-row flex-wrap items-center justify-between md:flex-nowrap">
                <SearchBar
                    className="w-full md:w-1/2"
                    onChange={handleSearch}
                />
                <CategoryAccordion
                    className="z-10 my-2 ml-0 h-12 w-full md:ml-4 md:w-1/2"
                    categories={allCategories as string[]}
                />
            </div>
            <p className="mt-4 text-center text-sm">
                Add keywords that describe your settings to help make search
                results more accurate.
            </p>
            <div className="border-brand-red my-8 flex h-24 items-center justify-center border-4 p-1">
                <p className="text-center text-sm font-medium">
                    <span className="text-brand-red m-2 text-2xl font-bold">
                        *
                    </span>
                    All features surrounded with a red border are device default
                    features.
                </p>
            </div>
            {filteredCategories &&
                filteredCategories.map((category) => {
                    return (
                        <SettingsList
                            key={category}
                            category={category}
                            selectedDevice={selectedDevice}
                            searchTerm={searchTerm}
                        />
                    )
                })}
            <div className="mt-4 flex items-center justify-between">
                <div className="flex flex-col items-center md:flex-row">
                    <Button
                        className="my-2 w-20 md:mr-2"
                        filled
                        secondary
                        onPress={handleBack}
                    >
                        Back
                    </Button>
                    <Button
                        className="mt-2 w-20 md:ml-2 md:mt-0"
                        filled
                        secondary
                        onPress={handleRestart}
                    >
                        Restart
                    </Button>
                </div>
                <h2 className="bg-brand-red mx-4 px-1 text-center text-lg font-bold text-white">
                    {selectedDevice.title}
                </h2>
                <Button
                    className="w-32"
                    filled
                    isDisabled={state.settings.size === 0}
                    onPress={() =>
                        show({
                            content: (
                                <BarcodeDialog
                                    settings={[...state.settings]}
                                    selectedDevice={selectedDevice}
                                    showSwitchButton
                                />
                            ),
                            modal: true,
                            dialogClassName: 'sm:w-1/3 sm:h-2/3',
                        })
                    }
                >
                    Generate
                </Button>
            </div>
        </section>
    )
}
