import { ReactNode } from 'react'
import { DeviceSelectorCard } from './device-selector-card'
import { Accordion } from '@codecorp/components'
import { useMcodesGetConfigurableDevicesQuery } from '@codecorp/web-api-wrapper'

export const DiscontinuedDevicesDropdown = () => {
    const { data: allProducts = [] } = useMcodesGetConfigurableDevicesQuery({})

    const AccordionItem: Record<string, ReactNode> = {
        'Discontinued Configuration Guides': (
            <div className="mb-4 grid h-full w-full grid-cols-1 gap-4 sm:grid-cols-7">
                {allProducts
                    .filter((product) => product.isDiscontinued)
                    .map((device) => {
                        return (
                            <DeviceSelectorCard
                                key={device.title}
                                device={device}
                                discontinued
                            />
                        )
                    })}
            </div>
        ),
    }

    return (
        <section className="my-4 w-full">
            <Accordion
                items={AccordionItem}
                type="multiple"
                fullWidth
            />
        </section>
    )
}
