import { equals } from 'ramda'
import {
    Dispatch,
    Ref,
    SetStateAction,
    createContext,
    useCallback,
    useContext,
    useRef,
    useState,
} from 'react'

const CollidesWithPopperContext = createContext<
    [Element[], Dispatch<SetStateAction<Element[]>>]
>([
    [],
    () => {
        // components that want to avoid being under a popper should be usable if there aren't poppers
        // so just warn in case they are using poppers and forgot to use the provider
        // eslint-disable-next-line no-console
        if (import.meta.env.DEV) console.warn('popper provider not set not set')
    },
])

export interface CollidesWithPopperProviderProps {
    children?: React.ReactNode
}
export const CollidesWithPopperProvider = (
    props: CollidesWithPopperProviderProps
) => (
    <CollidesWithPopperContext.Provider
        {...props}
        value={useState<Array<Element>>([])}
    />
)

/**
 * Returns a an array of elements with which poppers should avoid colliding
 */
export const useElementsWithWhichPopperShouldAvoidCollisions = () =>
    useContext(CollidesWithPopperContext)[0]

/**
 * Returns a ref that should be passed to an element to denote that poppers should display over it
 */
export const useRefForPopperBoundary = <
    // has to be generic since ObjectRef is invariant wrt T, ie Ref<Element> is not assignable to a Ref<HTMLDivElement>
    T extends Element = Element
>(): Ref<T | null> => {
    const [, setCollisions] = useContext(CollidesWithPopperContext)
    // track the last element registered so we can remove it from the list when it changes/is set to null
    const lastElementFromThisHook = useRef<Element | null>(null)

    return useCallback(
        (element: T) =>
            setCollisions((prev) => {
                const next = [
                    ...prev
                        // do this here rather than at the end since we may have added the same thing twice
                        .filter((el) => el !== lastElementFromThisHook.current),
                    element,
                ]
                    // remove the null if we just added it
                    .filter(Boolean)
                    // sort so that the order is deterministic
                    .sort()

                lastElementFromThisHook.current = element

                // keep referential equality if the array hasn't changed
                return equals(prev, next) ? prev : next
            }),
        [setCollisions]
    )
}
